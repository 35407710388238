import { Navigate, Route, Routes } from "react-router-dom";
import AboutService from "./components/ServicePage/AboutService";
import Bookdemo from "./components/ServicePage/Bookdemo";
import Content from "./components/ServicePage/Content";
import Headset from "./components/ServicePage/Headset";
import HowDiffrenet from "./components/ServicePage/HowDiffrenet";
import HowItWorks from "./components/ServicePage/HowItWorks";
import Partners from "./components/ServicePage/Partners";
import About from "./pages/About";
import Astrials from "./pages/Astrials";
import BookDemo from "./pages/BookDemo";
import ContactUS from "./pages/ContactUS";
import HomePage from "./pages/HomePage";
import Service from "./pages/Service";
import SuccessStories from "./pages/SuccesStories";
import { GlobalStyle } from "./style/GlobalStyle";

function App() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <Routes>
        {/* <Route path="/" element={<Navigate to="/home"></Navigate>}></Route> */}
        <Route path="/" element={<HomePage></HomePage>}></Route>
        <Route path="/about" element={<About></About>}></Route>
        <Route path="services/:id" element={<Service></Service>}>
          <Route path="about" element={<AboutService></AboutService>}></Route>
          <Route
            path="how-it-works"
            element={<HowItWorks></HowItWorks>}
          ></Route>
          <Route path="content" element={<Content></Content>}></Route>
          <Route path="headset" element={<Headset></Headset>}></Route>
          <Route
            path="how-diffrent"
            element={<HowDiffrenet></HowDiffrenet>}
          ></Route>
          {/* <Route path="bookdemo" element={<Bookdemo></Bookdemo>}></Route> */}
          <Route path="booke-demo" element={<BookDemo />}></Route>
          <Route path="partners" element={<Partners />}></Route>
        </Route>
        <Route path="bookdemo" element={<Bookdemo></Bookdemo>}></Route>

        <Route path="astrials/:id" element={<Astrials />}></Route>
        <Route path="contact-us" element={<ContactUS />}></Route>
        <Route path="succes-stories" element={<SuccessStories />}></Route>
      </Routes>
    </>
  );
}

export default App;
