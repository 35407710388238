import styled from "styled-components";
import bg from "../assets/aboutSection.png";
export const AboutSmartyStyle = styled.div`
  padding:0 2rem;
  background-image: url(${bg});
  background-position: top right;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;

  color: white;
  .header {
    background: transparent
      linear-gradient(98deg, #2b2b2b 0%, #ffffff 0%, #4288ec 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
  }
  .lorem {
    max-width: 40%;
    text-align: left;
    font-size: 16px;
    line-height: 25px;
  }
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 100vh;
  }
  .learn {
    color: #ffffff;
    border: 1px solid #1386f4;
    background-color: #037ef3;
    padding: 0.5rem 4rem;
    border-radius: 48px;
    font-size: 24px;
    cursor: pointer;
    margin-top: 2rem;
  }
`;
