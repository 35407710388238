import React from "react";
import astrials from "../assets/Astrials.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavBar from "../components/NavBar";
import styled from "styled-components";
import BG from "../assets/UnderWaterBG.jpg";
import { Button, Grid } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Footer from "../components/Footer";
import img1 from "../assets/slider/planet.png";
import img2 from "../assets/slider/planet2.png";
import img3 from "../assets/slider/planet3.png";
import contenet1 from "../assets/content1.png";
import contenet2 from "../assets/content2.png";
import contenet3 from "../assets/content3.png";
const Astrials = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <>
      <BigSection>
        {/* <NavBar></NavBar> */}
        <div className="hero">
          <div className="content">
            <Grid
              container
              columns={12}
              className="bbb"
              style={{ padding: "3rem 2rem" }}
            >
              <div className="overlay"></div>
              <Grid item xs={12}>
                <img src={astrials}></img>
                <div className="desc">
                  <p className="header">Astralis</p>
                  <p className="year">(2014)</p>
                </div>
                <div className="desc">
                  <p>The story of the supernova</p>
                  <p>7+ | 14 min. | VR / Fulldome</p>
                </div>
                <div className="desc">
                  <p>
                    {" "}
                    This bright and colorful film tells the story of a star’s
                    life cycle:
                  </p>
                  <p>
                    from its birth in gas dust nebulae to the end of life in the
                    form of a black hole or a supernova star. All these
                    phenomena are explained to the maximum extent possible and
                    are accessible for both children and students.
                  </p>
                  <MyButton
                    endIcon={<KeyboardArrowRightIcon />}
                    className="btn"
                  >
                    Watch a trailer
                  </MyButton>
                </div>
              </Grid>
            </Grid>
            <div className="backround-color">
              <Grid container columns={12}>
                <Grid item xs={12} md={6} className="border">
                  <div className="singlediv ">
                    <div className="content ">
                      <h2>Main information</h2>
                      <p>Studio: Aayushi Fulldome Films</p>
                      <p>Genre: Astronomy</p>
                      <p>Subject: Star’s life cycle</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="singlediv">
                    <div className="content">
                      <h2>Languages</h2>
                      <p>English, Russian, French, Hindi,</p>
                      <p> Portuguese, Turkish, Chinese</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <Grid container columns={12} className="slide">
              <Grid item xs={12}>
                <Slider {...settings}>
                  <div>
                    <img src={img1}></img>
                  </div>
                  <div>
                    <img src={img2}></img>
                  </div>
                  <div>
                    <img src={img3}></img>
                  </div>
                  <div>
                    <img src={img1}></img>
                  </div>
                  <div>
                    <img src={img2}></img>
                  </div>
                  <div>
                    <img src={img3}></img>
                  </div>
                </Slider>
              </Grid>
            </Grid>
            <Grid container columns={12}>
              <Grid item xs={12}>
                <p className="head">Similar shows</p>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <div className="singlediv">
                  <div className="content">
                    <img src={contenet2}></img>
                    <p className="name">Astralis</p>
                    <p className="desc">
                      Get to know the sea and ocean inhabitants
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <div className="singlediv">
                  <div className="content">
                    <img src={contenet3}></img>
                    <p className="name">Astralis</p>
                    <p className="desc">
                      Get to know the sea and ocean inhabitants
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <div className="singlediv">
                  <div className="content">
                    <img src={contenet1}></img>
                    <p className="name">Astralis</p>
                    <p className="desc">
                      Get to know the sea and ocean inhabitants
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <div className="singlediv">
                  <div className="content">
                    <img src={contenet2}></img>
                    <p className="name">Astralis</p>
                    <p className="desc">
                      Get to know the sea and ocean inhabitants
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </BigSection>
      <Footer></Footer>
    </>
  );
};
const BigSection = styled.div`
  .content {
  }
  .hero {
    position: relative;
    img {
      width: 18%;
    }
    .bbb {
      background-image: url(${BG});
      min-height: 100vh;
      background-attachment: fixed;
      background-position: top;
      background-repeat: no-repeat;
      /* background-origin: content-box; */
      background-size: cover;
      text-align: left;
      /* background-color: black; */
      color: black;
      background-size: 100vw;
      position: relative;
      /* background-color: #fafafa; */
      /* background-color: purple; */
      .overlay {
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background-image: -webkit-linear-gradient(
          top,
          rgba(250, 250, 250, 0),
          rgba(250, 250, 250, 1)
        );
      }
    }

    .backround-color {
      /* padding-top: 45px; */
      /* padding-bottom: 75px; */
      background-color: #fafafa;
      opacity: 0.9;
    }
    p {
      line-height: 35px;
      max-width: 45%;
    }
    .year {
      line-height: 10px;
    }
    .desc {
      padding: 0.7rem 0;
    }
    .header {
      font-weight: 800;
      font-size: 20px;
    }
  }
  .head {
    padding-top: 4rem;
    font-size: 28px;
    font-weight: 400;
    padding-left: 3rem;
  }
  .singlediv {
    /* padding: 1rem; */
    /* text-align: center; */
    h2,
    p {
      max-width: 100%;
    }
    p {
      opacity: 0.71;
    }
    .content {
      width: 50%;
      margin: 0 auto;
    }
  }
  .border {
    /* border-right: 1px solid #707070; */
    /* background-clip: padding-box; */
  }
  .slide {
    img {
      width: 100%;
    }
  }
  .singlediv {
    img {
      width: 100%;
    }
    .content {
      width: 75%;
      text-align: center;
      .name {
        font-size: 28px;
        font-weight: 500;
      }
    }
    a {
      color: inherit;
    }
  }
`;
const MyButton = styled(Button)`
  && {
    border: 1px solid #ffffff;
    border-radius: 46px;
    background-color: black;
    color: white;
    padding: 1rem 3rem;
    font-size: 14px;
    margin: 2rem 0;
  }
`;
export default Astrials;
