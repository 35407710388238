import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import walkingman from "../../assets/walkingman.png";
import img from "../../assets/video2.png";
import { useLocation } from "react-router-dom";

const HowItWorks = () => {
  const location = useLocation();
  const id = location?.pathname.split("/")[2];
  return (
    <Bigsection>
      {/* <p className="header"> How it works</p> */}
      <p className="lorem">
        {id === "6" ? (
          <>
            {" "}
            Smarty VCLab works by creating a simulated environment that the
            student can interact with in a seemingly real way under his
            teacher’s control. This is achieved using VR headsets which contain
            displays, sensors, and other components that track the user's
            movements and provide input to the VR environment.
          </>
        ) : (
          <></>
        )}
        {id === "7" ? (
          <>
            {" "}
            Smarty Augmented Reality (AR) works by creating a simulated
            environment that the student can interact with in a seemingly real
            way under his teacher’s control. This is achieved using VR headsets
            which contain displays, sensors, and other components that track the
            user's movements and provide input to the VR environment.
          </>
        ) : (
          <></>
        )}
        {id === "8" ? (
          <>
            {" "}
            Our VR training Solution uses immersive and interactive environments
            to allow trainees to practice and develop skills in a safe and
            controlled environment. By following these steps, trainers can
            create engaging and effective training experiences that can improve
            learning outcomes.
          </>
        ) : (
          <></>
        )}
        {id === "9" ? (
          <>
            {" "}
            <h3>
              {" "}
              Our 360 VR movies for schools typically involves the following
              steps:
            </h3>
            <ul>
              <li>
                {" "}
                A- Scheduling: The first step is to schedule the 360 VR movies
                with the school. Our team will coordinate with the school to
                determine the best time and location for the show.{" "}
              </li>
              <li>
                {" "}
                B- Set-up: On the day of the show, our team will arrive at the
                school and set up the VR Headsets and distribute it for
                students.{" "}
              </li>
              <li>
                {" "}
                C- Showtime: Once the Headsets are distributed to students, our
                team will explain them how to use. Our shows include movies from
                international studios and the school will select the convenient
                movies deepening on student age and grade. The show may also
                include interactive elements, such as quizzes or games, to
                engage the audience.{" "}
              </li>
              <li>
                {" "}
                D- Q&A: After the show, the presenter may hold a Q&A session to
                answer questions from the audience about astronomy, space, and
                the universe.
              </li>
              <li>
                {" "}
                E- Take-down: Once the show is over, we can do the same for
                other shows and movies if required. Finally, our team will
                collect the Headsets and see you again.
              </li>
            </ul>
          </>
        ) : (
          <></>
        )}
        {id === "10" ? (
          <>
            {" "}
            We get the learning objectives and the content to present. Then we
            develop the games and apps experience, test and refine it, and
            deploy it to the target audience.
          </>
        ) : (
          <></>
        )}
        {/* {id === "11" ? (
          <>
            {" "}
            Smarty AR services work by enhancing the learning experience by
            overlaying digital information onto the physical world. AR
            technology can create immersive learning environments that enable
            students to interact with 3D objects, animations, and simulations in
            real-time.
          </>
        ) : (
          <></>
        )} */}
        {id === "11" ? (
          <>
            {" "}
            <h3>
              {" "}
              Our Educational portable planetarium shows for schools typically
              involve the following steps:
            </h3>
            <ul>
              <li>
                {" "}
                A- Scheduling: The first step is to schedule the portable
                planetarium show with the school. The planetarium team will
                coordinate with the school to determine the best time and
                location for the show.{" "}
              </li>
              <li>
                {" "}
                B- Set-up: On the day of the show, our planetarium team will
                arrive at the school and set up the portable planetarium dome.
                This will involve inflating the dome, inflating the chairs for
                students, installing the show material, and arranging the
                seating inside the dome.{" "}
              </li>
              <li>
                {" "}
                C- Showtime: Once the Headsets are distributed to students, our
                team will explain them how to use. Our shows include movies from
                international studios and the school will select the convenient
                movies deepening on student age and grade. The show may also
                include interactive elements, such as quizzes or games, to
                engage the audience.{" "}
              </li>
              <li>
                {" "}
                D- Q&A: After the show, the presenter may hold a Q&A session to
                answer questions from the audience about astronomy, space, and
                the universe.
              </li>
              <li>
                {" "}
                E- Take-down: Once the show is over, the planetarium team will
                take down the dome and pack up the equipment.
              </li>
            </ul>
          </>
        ) : (
          <></>
        )}
        {id === "12" ? (
          <>
            {" "}
            We are using VR in behavioral therapy by creating a virtual
            environment that replicates the real-world situations that trigger
            anxiety or other negative behaviors. We simulate realistic
            environments and scenarios, which can be used to enhance the
            effectiveness of behavioral therapy
          </>
        ) : (
          <></>
        )}
      </p>
      {/* <Grid container columns={12} spacing={10}>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <img src={walkingman}></img>
            <p className="head">Engaging Learning</p>
            <p className="loremsingle">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitatio
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <img src={walkingman}></img>
            <p className="head">Engaging Learning</p>
            <p className="loremsingle">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitatio
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <img src={walkingman}></img>
            <p className="head">Engaging Learning</p>
            <p className="loremsingle">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitatio
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <img src={walkingman}></img>
            <p className="head">Engaging Learning</p>
            <p className="loremsingle">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitatio
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <img src={walkingman}></img>
            <p className="head">Engaging Learning</p>
            <p className="loremsingle">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitatio
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <img src={walkingman}></img>
            <p className="head">Engaging Learning</p>
            <p className="loremsingle">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitatio
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <img src={walkingman}></img>
            <p className="head">Engaging Learning</p>
            <p className="loremsingle">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitatio
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <img src={walkingman}></img>
            <p className="head">Engaging Learning</p>
            <p className="loremsingle">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitatio
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <img src={walkingman}></img>
            <p className="head">Engaging Learning</p>
            <p className="loremsingle">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitatio
            </p>
          </div>
        </Grid>
      </Grid> */}

      {id === "6" ? (
        <>
          <Grid container columns={12}>
            <Grid item xs={12} className="second">
              <p className="header">Watch a trailer</p>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/_52YOGTjIx8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {id === "8" ? (
        <>
          <Grid container columns={12}>
            <Grid item xs={12} className="second">
              <p className="header">Watch a trailer</p>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/Vg-2igntipw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {id === "12" ? (
        <>
          <Grid container columns={12}>
            <Grid item xs={12} className="second">
              <p className="header">Watch a trailer</p>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/DI75lIDcVEc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {id === "12" ? (
        <>
          <Grid container columns={12}>
            <Grid item xs={12} className="second">
              <p className="header">Watch a trailer</p>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/DI75lIDcVEc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {id === "7" ? (
        <>
          <Grid container columns={12}>
            <Grid item xs={12} className="second">
              <p className="header">Watch a trailer</p>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/FgH6c7TyT-I"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Bigsection>
  );
};
const Bigsection = styled.div`
  padding: 0 4rem;
  .header {
    text-align: left !important;
    text-transform: uppercase;
    background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: bold;
    width: 340px;
  }
  .lorem {
    line-height: 35px;
    padding-top: 2rem;
    opacity: 0.8;
    width: 55%;
  }
  .singlediv {
    background-color: #292929;
    text-align: center;
    padding: 1rem;
    .head {
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 2px;
      padding: 1rem 0;
    }
    .loremsingle {
      line-height: 25px;
    }
  }
  .second {
    padding: 3rem 0;
    p {
      padding: 2rem 0;
    }
    .video,
    iframe {
      width: 100%;
      border: 1px solid #27549e;
      border-radius: 10px;
      cursor: pointer;
    }
  }
  ul {
    margin: auto;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    list-style: none;
    padding: 0 !important;

    li {
      font-weight: 400;
      font-size: 14px !important;
      opacity: 1 !important;
      text-align: left;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      font-size: 14px !important;
    }
  }
`;
export default HowItWorks;
