import { Grid, Rating } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import img from "../assets/girl.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import reviewimg from "../assets/reviewOne.png";
import comma from "../assets/comma.png";
import BackButton, { BackButtonStyle } from "../components/Layout/BackButton";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import { GetAllSuccessStories } from "../Redux/Slices/SuccessStories/SuccessStories";

const SuccessStories = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(5);
  const { allSuccessStories } = useSelector((state) => state?.sucessStory);
  React.useEffect(() => {
    dispatch(GetAllSuccessStories());
  }, []);
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/");
  };
  return (
    <>
      {/* <NavBar></NavBar> */}
      <Bigsection>
        <BackButtonStyle
          endIcon={<KeyboardBackspaceIcon></KeyboardBackspaceIcon>}
          onClick={() => {
            goBack();
          }}
        >
          Back
        </BackButtonStyle>

        <div className="content">
          <Grid container columns={12}>
            <Grid item xs={12}>
              <p className="header">Success stories</p>
              <p className="lorem">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo.Lorem ipsum dolor m, qLorem ipsum
                dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo.Lorem ipsum dolor m, qLorem ipsum dolor
                sit amet, consectetur adipisicing elit,
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            columns={12}
            // justifyContent="center"
            // alignItems="center"
            className="photos"
            spacing={11}
          >
            <Grid item xs={12} md={6} lg={3}>
              <div className="singleDiv">
                <img src={img}></img>
                <div className="overlay">
                  <div className="written">
                    {" "}
                    <p className="name">Name </p>
                    <p className="job">CEO AT ADGROUP</p>
                  </div>
                  <div>
                    {" "}
                    <button>
                      <PlayArrowIcon />
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <div className="singleDiv">
                <img src={img}></img>
                <div className="overlay">
                  <div className="written">
                    {" "}
                    <p className="name">Name </p>
                    <p className="job">CEO AT ADGROUP</p>
                  </div>
                  <div>
                    {" "}
                    <button>
                      <PlayArrowIcon />
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <div className="singleDiv">
                <img src={img}></img>
                <div className="overlay">
                  <div className="written">
                    {" "}
                    <p className="name">Name </p>
                    <p className="job">CEO AT ADGROUP</p>
                  </div>
                  <div>
                    {" "}
                    <button>
                      <PlayArrowIcon />
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <div className="singleDiv">
                <img src={img}></img>
                <div className="overlay">
                  <div className="written">
                    {" "}
                    <p className="name">Name </p>
                    <p className="job">CEO AT ADGROUP</p>
                  </div>
                  <div>
                    {" "}
                    <button>
                      <PlayArrowIcon />
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            columns={12}
            className="reviews"
            alignItems="center"
            justifyContent="space-around"
          >
            <Grid item xs={12} md={6} className="left">
              <img className="reviewing" src={reviewimg}></img>
            </Grid>
            <Grid item xs={12} md={6} className="left">
              <img className="comma" src={comma}></img>
              <p className="loremtwo">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo.Lorem ipsum dolor m, qLorem ipsum
                dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo.Lorem ipsum dolor m, qLorem ipsum dolor
                sit amet, consectetur adipisicing elit,
              </p>
              <Rating name="read-only" value={value} readOnly />
              <div className="desc">
                <p className="name">Albert Flores</p>
                <p className="job">CEO at ADGROUP</p>
              </div>
            </Grid>

            <Grid item xs={12} md={6} className="left">
              <img className="comma" src={comma}></img>
              <p className="loremtwo">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo.Lorem ipsum dolor m, qLorem ipsum
                dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo.Lorem ipsum dolor m, qLorem ipsum dolor
                sit amet, consectetur adipisicing elit,
              </p>
              <Rating name="read-only" value={value} readOnly />
              <div className="desc">
                <p className="name">Albert Flores</p>
                <p className="job">CEO at ADGROUP</p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} className="right">
              <img className="reviewing" src={reviewimg}></img>
            </Grid>
          </Grid>
        </div>
      </Bigsection>
      <Footer></Footer>
    </>
  );
};
const Bigsection = styled.div`
  min-height: 100vh;
  background-color: black;
  .content {
    text-align: center;
  }
  .header {
    text-align: center;
    text-transform: uppercase;
    background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
    font-weight: bold;
    padding: 2rem 0;
  }
  .lorem {
    font: normal normal medium 16px/37px Rajdhani;
    color: white;
    width: 60%;
    margin: auto;
    line-height: 30px;
    opacity: 0.9;
  }
  .photos {
    max-width: 80%;
    margin: auto;
  }
  .singleDiv {
    width: 100%;
    /* padding: 2.5rem; */
    position: relative;
    img {
      width: 100%;
      position: relative;
    }
    &:hover {
      transition: 1.3s all;

      &:after {
        display: block;
      }
      .overlay {
        visibility: visible;
      }
    }
    .overlay {
      position: absolute;
      width: 100%;
      bottom: 20px;
      display: flex;
      left: 5px;
      justify-content: space-around;
      visibility: hidden;
      color: white;
      transition: 0.3s all;
      button {
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: white;
        border: 2px solid white;
        background-color: #3e3f40;
        opacity: 0.8;
        padding: 0.3em 0.4rem;
        border-radius: 8px;
        cursor: pointer;
      }
      .written {
        text-align: left;
      }
      .name {
        font-size: 18px;
        font-weight: 400;
      }
      .job {
        font-size: 13px;
        /* font-weight: 400; */
      }
    }
  }
  .reviews {
    width: 65%;
    margin: auto;
    padding-top: 5rem;
    .reviewing {
      width: 60%;
    }
  }
  .loremtwo {
    color: white;
    font: normal normal medium 16px/37px Rajdhani;
    color: white;
    margin: auto;
    line-height: 25px;
    opacity: 0.9;
    text-align: left;
    width: 100%;
  }
  .comma {
    width: 10%;
    text-align: left;
  }
  .left {
    text-align: left;
    margin: 2rem 0;
  }
  .desc {
    padding: 0.2rem 0;
    .name {
      text-align: left;
      font: normal normal 600 20px/37px Rajdhani;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .job {
      text-align: left;
      font: normal normal medium 14px/37px Rajdhani;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
  .right {
    text-align: right;
    margin: 2rem 0;
  }
`;

export default SuccessStories;
