import { Grid } from "@mui/material";
import React from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import BG from "../assets/servicesBG/1917-x-3407-3-min.png";
import bb from "../assets/zztop.jpg";
import { BackButtonStyle } from "../components/Layout/BackButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllServices,
  GetSingleService,
  servicesReset,
} from "../Redux/Slices/ServicesSlice/ServicesSlice";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";

const Service = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { singleService, servicesStatus, allServices } = useSelector(
    (state) => state?.services
  );

  React.useEffect(() => {
    let apis = async () => {
      await dispatch(GetSingleService(id));
      await dispatch(GetAllServices());
      await dispatch(servicesReset());
    };
    apis();
  }, [id]);
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/#services");
  };
  let smartyServices = allServices?.filter((e) => e.company_id === 2);

  const [imageLoading, setImageLoading] = React.useState(true);
  React.useEffect(() => {
    const img = new Image();
    img.src = singleService?.background_path;
    img.onload = () => {
      console.log("Loaded");
      setImageLoading(false);
    };
  }, [singleService?.background_path]);
  return (
    <>
      {servicesStatus === "loading" ? (
        <>
          <Loader></Loader>
        </>
      ) : (
        <>
          {" "}
          <Bigsection image={singleService?.background_path}>
            <BackButtonStyle
              endIcon={<KeyboardBackspaceIcon></KeyboardBackspaceIcon>}
              onClick={() => {
                goBack();
              }}
            >
              Back
            </BackButtonStyle>

            <div className="hero">
              <Grid container columns={12} sx={{ paddingBottom: "5rem" }}>
                <Grid item xs={12}>
                  <h1 className="MiddleHeader"> {singleService?.title}</h1>
                </Grid>
              </Grid>
              <div className="main">
                <Grid container columns={12}>
                  <Grid item xs={12} md={2}>
                    <ul>
                      <NavLink to="about">
                        {" "}
                        <li>
                          <div className="circle"></div> About
                        </li>
                      </NavLink>
                      <NavLink to="how-it-works">
                        <li>
                          <div className="circle"></div>How it works
                        </li>
                      </NavLink>
                      <NavLink to="content">
                        {" "}
                        <li>
                          <div className="circle"></div>
                          {id === "9" ||
                          id === "09" ||
                          id === "10" ||
                          id === "11" ? (
                            <>Catalogue</>
                          ) : (
                            <>Content</>
                          )}
                        </li>
                      </NavLink>
                      {id === "11" ? (
                        <>
                          <NavLink to="partners">
                            {" "}
                            <li>
                              <div className="circle"></div>
                              Partners
                            </li>
                          </NavLink>
                        </>
                      ) : (
                        <></>
                      )}
                      {id === "6" ? (
                        <>
                          <NavLink to="how-diffrent">
                            {" "}
                            <li>
                              <div className="circle"></div> How we are diffrent
                            </li>
                          </NavLink>
                          <NavLink to="headset">
                            {" "}
                            <li>
                              <div className="circle"></div> Headset
                            </li>
                          </NavLink>
                        </>
                      ) : (
                        <></>
                      )}
                      <NavLink to="booke-demo">
                        <li>
                          <div className="circle"></div>Book a demo
                        </li>
                      </NavLink>
                    </ul>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <div className="rightside">
                      <Outlet></Outlet>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Footer></Footer>
          </Bigsection>
        </>
      )}
    </>
  );
};
const Bigsection = styled.div`
  /* background-image: url(${bb}); */
  background-image: url(${(props) => props.image});
  min-height: 100vh;
  /* background-attachment: fixed; */
  background-position: top;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: cover;
  text-align: left;
  background-color: black;
  color: white;
  .MiddleHeader {
    text-align: center;
    text-transform: uppercase;
    /* background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box; */
    color: #037ef3;
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    /* font-size: 50px; */
    /* -webkit-text-stroke-width: 0.5px; */
    /* -webkit-text-stroke-color: white; */
    font-weight: bold;
    padding: 2rem 0;
  }
  .main {
    ul {
      margin: auto;
      display: flex;
      flex-direction: column;
      list-style: none;
      padding-left: 5rem;
      /* border-right:1px solid white ; */
      li {
        font-weight: 400;
        font-size: 18px;
        padding: 1.5rem 0;
        opacity: 0.53;
        text-align: left;
        display: flex;
        align-items: center;
        position: relative;
        .circle {
          width: 12px;
          height: 12px;
          border-radius: 50px;
          background-color: white;
          margin: 0 0.8rem;
          display: none;
          position: absolute;
          left: -2.5rem;
        }
      }
      a {
        color: inherit;
        text-decoration: none;
      }
      a.active {
        li {
          opacity: 1;
        }
        .circle {
          display: block;
        }
      }
    }
  }
  .rightside {
    /* background-image: url(${BG});
    min-height: 100vh;
    background-attachment: fixed;
    background-position: top right;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: auto;
    text-align: left; */
  }
`;

export default Service;
