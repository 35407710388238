import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageLazyLoading = ({ image }) => {
  return (
    <div>
      {" "}
      <LazyLoadImage
        // alt={image.alt}
        // height={image.height}
        src={image} // use normal <img> attributes as props
        // width={image.width}
      />
    </div>
  );
};

export default React.memo(ImageLazyLoading);
