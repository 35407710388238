import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import video from "../../assets/videoAbout.png";
const NeedMore = () => {
  return (
    <BigSection>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <h3>Need to know more</h3>
          <img src={video}></img>
          <h3>Sucess partners</h3>
        </Grid>
      </Grid>
      <Grid container columns={12} className="partner">
        <Grid item xs={3}>
          <p>Forpes</p>
        </Grid>
        <Grid item xs={3}>
          <p>Forpes</p>
        </Grid>
        <Grid item xs={3}>
          <p>Forpes</p>
        </Grid>
        <Grid item xs={3}>
          <p>Forpes</p>
        </Grid>
        <Grid item xs={3}>
          <p>Forpes</p>
        </Grid>
        <Grid item xs={3}>
          <p>Forpes</p>
        </Grid>
        <Grid item xs={3}>
          <p>Forpes</p>
        </Grid>
        <Grid item xs={3}>
          <p>Forpes</p>
        </Grid>
      </Grid>
    </BigSection>
  );
};
const BigSection = styled.div`
  text-align: center;
  background-color: black;
  padding: 4rem 0;
  h3 {
    text-transform: uppercase;
    background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: bold;
  }
  img {
    width: 80%;
    padding: 5rem 0;
  }
  p {
    color: #b5b5b5;
    /* font-weight: 800; */
    font-size: 50px;
  }
  .partner {
    padding: 2rem 5rem;
  }
`;
export default NeedMore;
