import { Grid } from "@mui/material";
import React from "react";
import { WhyStyle } from "../style/whySmartyStyle";
import ReadMore from "./ReadMore";

import { useSelector } from "react-redux";
import CibCard from "./Layout/CibCard";
import { Fade } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";
import { arr } from "../StaticArrays";

const WhySmarty = () => {
  const { allPages, singlePage } = useSelector((state) => state?.pages);
  const smartPages = allPages?.filter((e) => e.company_id === 2);
  const whySection = smartPages?.filter((e) => e?.section === "why");
  const LastIndex = whySection[whySection?.length - 1];
  return (
    <WhyStyle id="why">
      <Grid container columns={12}>
        <Grid item xs={12}>
          <div>
            <Fade>
              <p className="header">{LastIndex?.title}</p>
            </Fade>
            <p className="lorem">{LastIndex?.description}</p>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        columns={12}
        spacing={10}
        style={{ padding: "3rem 5rem", textAlign: "center" }}
      >
        {/* <Grid item xs={12} md={6} lg={3}>
          <div className="singleDiv">
            <img alt="img" src={iconOne}></img>
            <p className="install">Increasing Student Engagement</p>
            <p className="lorem2">
              <ReadMore>
                SMARTY Virtual Reality (VR) platform transforms educational
                content delivery, allowing students to learn in a more engaging
                and effective way. By ‎creating a fully immersive, rich virtual
                world that gives the student the chance not ‎only to see it,
                ‎but also to interact with it, and get accurate responses based
                on real-world ‎possibilities, which ‎motivates student
                engagement and participation.
              </ReadMore>
            </p>
          </div>
        </Grid> */}

        <Grid item xs={12}>
          {/* <button className="learn">Learn more</button> */}
        </Grid>
        {[...new Set(arr)]?.map((e, i) => {
          return (
            <Grid item xs={12} md={6} lg={3} key={i}>
              <Slide duration={500} triggerOnce={true} direction={e.direction}>
                <CibCard card={e}></CibCard>
              </Slide>
            </Grid>
          );
        })}
        {/* {arr?.map((e, i) => {
          return (
            <Grid item xs={12} md={6} lg={3} key={i}>
              <Slide triggerOnce={true} cascade>
                <CibCard card={e}></CibCard>
              </Slide>
            </Grid>
          );
        })} */}
      </Grid>
    </WhyStyle>
  );
};

export default WhySmarty;
