import {
  FormControl,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { country_codes } from "../Countries";
import { TalkStyle } from "../style/TalkStyle";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {},
  "& .MuiInputBase-input": {
    borderRadius: 20,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    border: " 1px solid #ffffff",
    fontSize: 16,
    margin: "1rem 0",
    padding: "1rem",
    color: "white",
    textAlign: "left",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 20,
      // borderColor: "#80bdff",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      "label + &": {
        // marginTop: theme.spacing(3),
        color: "white",
      },
    },
  },
}));
const TalkWithUs = () => {
  const [data, setData] = React.useState();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };
  console.log("data", data);

  return (
    <TalkStyle id="contact-us">
      <p className="header">Contact us</p>
      <p className="lorem">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo.Lorem ipsum dolor m, q
      </p>
      <div className="form">
        <form>
          <input placeholder="Name" onChange={handleChange} name="name"></input>
          <input
            placeholder="E_mail"
            onChange={handleChange}
            name="email"
          ></input>
          <div className="countryCode">
            <FormControl
              fullWidth
              className="formControl"
              sx={{ m: 1, width: "27ch" }}
            >
              <InputLabel sx={{ color: "white" }}>Country Code</InputLabel>
              <Select
                id="demo-simple-select"
                required
                // value={data.termId}
                label="Country Code"
                onChange={handleChange}
                defaultValue=""
                input={<BootstrapInput />}
                // autoWidth
                name="code"
              >
                {country_codes?.map((data, index) => (
                  <MenuItem key={index} value={data.code}>
                    {data.name} {data?.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <BootstrapInput
              label="Phone"
              placeholder="Phone"
              id="outlined-start-adornment"
              // sx={{ m: 1, width: "25ch" }}
              fullWidth
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       {data?.code}
              //     </InputAdornment>
              //   ),
              // }}
              onChange={handleChange}
              name
            />
          </div>
          <textarea
            placeholder="Subject"
            rows="4"
            cols="50"
            onChange={handleChange}
            name="subject"
          ></textarea>
          <button>GET IN TOUCH</button>
        </form>
      </div>
    </TalkStyle>
  );
};

export default TalkWithUs;
