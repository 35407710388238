import styled from "styled-components";

export const FAQStyle = styled.div`
  text-align: center;
  background-color: black;
  color: white;
  padding: 3rem 0;
  .containerOne {
    text-align: center;
    margin: auto;
    width: 50%;
  }
  .header {
    background: transparent
      linear-gradient(98deg, #2b2b2b 0%, #ffffff 0%, #4288ec 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
  }
  .container {
    text-align: center;
    margin: auto;
    width: 35%;

    .flex {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid white;
      flex-flow: row wrap;
      padding: 1.5rem 0;

      .question {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      svg {
        color: #037ef3;
        cursor: pointer;
      }
    }
  }
  .lastdiv {
    background-color: #060d19;
    margin: auto;
    width: 60%;
    margin: 3rem auto;
    padding-bottom: 1rem;
    .middle {
      padding: 1rem 0;
      color: #9d9d9d;
    }
    button {
      color: #ffffff;
      background-color: transparent;
      padding: 0.5rem 2.5rem;
      border: 1px solid #1386f4;
      border-radius: 25px;
      font-size: 17px;
      cursor: pointer;
    }
  }
  .answer {
    text-align: left;
    transition: all 0.5s;
  }
  .show {
    visibility: visible;
  }
  .hide {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
`;
