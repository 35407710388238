import styled from "styled-components";

export const HeaderStyle = styled.div`
  padding: 1rem 2rem;
  width: 100%;
  background-color: black;
  opacity: 1;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .header {
    background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: bold;
  }

  .lorem {
    color: white;
    padding: 2rem 0;
    line-height: 25px;

  }
  img {
    width: 75%;
  }

  .buttons {
    display: flex;
  }
  .demo {
    background-color: #377ceb;
    color: white;
    padding: 1rem 2rem;
    border-radius: 48px;
    font-size: 18px;
  }
  .how {
    background: none;
    color: white;
    font-size: 18px;
    margin: 0 1rem;
  }
  @media (max-width: 768px) {
    .header {
      font-size: 40px;
    }
    .demo {
      padding: 1rem 1rem;
      font-size: 10px;
    }
    .how {
      padding: 1rem 1rem;
      font-size: 10px;
    }
  }
  @media (max-width: 900px) {
    .right-div {
      text-align: center;
    }
  }
  .right-div {
    /* text-align: right;
    position: relative;
    position: relative;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; */
  }
  #myVideo {
    width: 100%;
    /* height: 100vh; */
    /* height: auto; */
    object-fit: cover;
    /* position: fixed; */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 30px;
  }
`;
