import React from "react";
import styled from "styled-components";
import bfi from "../../assets/BFI.png";
import commingSoon from "../../assets/commingSoon.png";
const Partners = () => {
  return (
    <Bigsection>
      <div className="square-holder">
        <img alt="" src={bfi} />
      </div>
      <div className="square-holder">
        <img alt="" src={commingSoon} />
      </div>
      <div className="square-holder">
        <img alt="" src={commingSoon} />
      </div>
      <div className="square-holder">
        <img alt="" src={commingSoon} />
      </div>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  div {
    margin: 0 1rem;
    overflow: hidden;
    width: 280px;
    height: 100px;
  }
  .square-holder {
    /* padding: 30px; */
    /* border: 1px solid #cecece; */
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    min-height: 200px;
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px #cecece;

  }

  .square-holder img {
    max-width: 100%;
    filter: grayscale(100%);
    transition: all 0.3s;
  }

  .square-holder:hover img {
    filter: none;
    scale: 1.1;
  }
  
  .square-holder:hover  {
    border: 1px solid #cecece;

  }
  
`;
export default Partners;
