import { Grid } from "@mui/material";
import React from "react";
import { FooterStyle } from "../style/FooterStyle";
import logo from "../assets/logo-f.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import location from "../assets/location.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
const Footer = () => {
  return (
    <FooterStyle>
      <Grid
        container
        columns={12}
        spacing={8}
        justifyContent="center"
        alignItems="start"
      >
        <Grid item xs={12} md={4} lg={3}>
          <ul className="firstUl">
            <li>
              <a href="https://adgrouptech.com/" target="_blank">
                <img src={"https://adgrouptech.com/ad-logo.svg"}></img>
              </a>
            </li>
            <li>
              <p>
                We’re on a mission to constantly evolve the way digital and
                integrated solutions are done so that we can enable working
                smart and not just hard.
              </p>
            </li>
            <li>
              <FacebookIcon></FacebookIcon> <InstagramIcon></InstagramIcon>{" "}
              <LinkedInIcon></LinkedInIcon>{" "}
              <a href="https://www.youtube.com/@smartyedu" target="_blank">
              <YouTubeIcon ></YouTubeIcon>
              </a>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={4} lg={3} style={{ paddingLeft: "120px" }}>
          <h3>Quick Links</h3>
          <ul>
            <li>About us</li>
            <li>Our Services</li>
            <li>News & Articles</li>
            <li>Privacy & policy</li>
            <li>Careers</li>
            <li>Sister companies</li>
            <li>Our Projects</li>
            <li>Contact us</li>
          </ul>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <h3>Contact us</h3>
          <ul className="padding">
            <li>Dubai,Business Bay UAE</li>
            <li>contact@Smarty.com</li>
            <li>0111000000000</li>
          </ul>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ul>
            <li>
              <img className="location" src={location}></img>
            </li>
          </ul>
        </Grid>
      </Grid>
    </FooterStyle>
  );
};

export default Footer;
