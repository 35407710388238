import {
  Grid,
  InputBase,
  TextField,
  alpha,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "../components/Footer";
import { BackButtonStyle } from "../components/Layout/BackButton";
import NavBar from "../components/NavBar";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import bg from "../assets/bookDemo.png";
import { country_codes } from "../Countries";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import Loader from "../components/Loader";
import Swal from "sweetalert2";
import PrivacyAndPolicy from "../components/PrivacyAndPolicy";

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label.Mui-root": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  input: {
    color: "white",
    svg: {
      color: "white",
    },
  },
  svg: {
    color: "white",
  },
});

const BookDemo = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [data, setData] = React.useState({
    service_id: id,
  });
  const [value, setValue] = React.useState(null);

  const handleChange = (e) => {
    const { value, name, checked } = e.target;
    setData({ ...data, [name]: value });
  };
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data?.privacy) {
      await setLoading(true);
      await axios({
        baseURL: "https://api.adgrouptech.com/api/v1/request-demo",
        method: "POST",
        headers: { "X-Custom-Header": "foobar" },
        data: data,
      })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "You have requsted a demo",
            showConfirmButton: true,
            timer: 5500,
          });
        })
        .catch((e) => {});
    } else {
      Swal.fire({
        title: "You Need to accept privacy and policy",
        text: "You won't be able to book a demo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, accept it ",
      }).then((result) => {
        if (result.isConfirmed) {
          setData({ ...data, privacy: true });
        }
      });
    }
  };

  console.log("data", data);
  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          {" "}
          <Bigsection>
            {/* <NavBar></NavBar> */}
            {/* <BackButtonStyle
        endIcon={<KeyboardBackspaceIcon></KeyboardBackspaceIcon>}
        onClick={() => {
          goBack();
        }}
      >
        Back
      </BackButtonStyle> */}

            <div className="hero">
              <form onSubmit={handleSubmit}>
                {" "}
                <Grid container columns={12} spacing={2.5}>
                  <Grid item xs={12}>
                    <p className="header">Get in touch</p>
                    <p>Our friendly team would love to hear from you.</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CssTextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      size="medium"
                      required
                      fullWidth
                      InputLabelProps={{ className: "lablecolor" }}
                      name="name"
                      onChange={handleChange}
                    ></CssTextField>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <CssTextField
                      id="outlined-basic"
                      label="Last name"
                      variant="outlined"
                      size="medium" required
                      fullWidth
                      InputLabelProps={{ className: "lablecolor" }}
                      name="last_name"
                      onChange={handleChange}
                    ></CssTextField>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <CssTextField
                      id="outlined-basic"
                      label="Company name"
                      variant="outlined"
                      size="medium"
                      required
                      fullWidth
                      InputLabelProps={{ className: "lablecolor" }}
                      name="company_name"
                      onChange={handleChange}
                    ></CssTextField>
                  </Grid>
                  <Grid item xs={12}>
                    {" "}
                    <CssTextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      size="medium"
                      required
                      fullWidth
                      InputLabelProps={{ className: "lablecolor" }}
                      name="email"
                      onChange={handleChange}
                    ></CssTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <CssTextField
                      select
                      label="Countrys"
                      defaultValue=""
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      size="medium"
                      required
                      InputLabelProps={{ className: "lablecolor" }}
                      name="country"
                      onChange={handleChange}
                    >
                      {country_codes.map((option, i) => (
                        <MenuItem key={i} value={option.code}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </CssTextField>
                  </Grid>
                  <Grid item xs={12}>
                    {" "}
                    <CssTextField
                      id="outlined-basic"
                      label="Phone number"
                      variant="outlined"
                      size="medium"
                      required
                      fullWidth
                      InputLabelProps={{ className: "lablecolor" }}
                      name="phone"
                      onChange={handleChange}
                    ></CssTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <CssTextField
                      id="outlined-basic"
                      label="Message"
                      variant="outlined"
                      size="medium"
                      required
                      fullWidth
                      InputLabelProps={{ className: "lablecolor" }}
                      multiline
                      rows={5}
                      name="message"
                      onChange={handleChange}
                    ></CssTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <CssTextField
                      type="datetime-local"
                      fullWidth
                      name="time"
                      label="Time (GMT+2)"
                      variant="outlined"
                      size="medium"
                      required
                      InputLabelProps={{
                        className: "lablecolor",
                        shrink: true,
                      }}
                      onChange={handleChange}
                    ></CssTextField>
                    <span style={{ color: "Highlight" }}>
                      Notice : Date and Time in (GMT+2)
                    </span>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns} fullWidth>
                <DatePicker
                  fullWidth
                  label="Basic example"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <CssTextField {...params} />}
                />
              </LocalizationProvider> */}
                  </Grid>
                  <Grid item xs={12} className="agreeDiv">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={data?.privacy}
                      onChange={(e) => {
                        setData({ ...data, privacy: e.target.checked });
                      }}
                      name="privacy"
                      checked={data?.privacy ? true : false}
                    />{" "}
                    <p className="agree">
                      You agree to our friendly privacy policy.{" "}
                      <button
                        className="privacy"
                        type="button"
                        onClick={handleOpen}
                      >
                        See Privacy and ploicy
                      </button>
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <button className="submit">submit</button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Bigsection>
        </>
      )}
      <PrivacyAndPolicy
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
      ></PrivacyAndPolicy>
    </>
  );
};
const Bigsection = styled.div`
  /* background-image: url(${bg});
  background-position: top right;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  position: relative; */
  min-height: 100vh;
  &:before {
    /* content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0; */
  }
  .hero {
    /* text-align: center; */
    padding-bottom: 2rem;

    .header {
      /* background: transparent
        linear-gradient(98deg, #2b2b2b 0%, #ffffff 0%, #4288ec 100%); */
      /* -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
      /* font-size: 50px; */
      /* font-weight: bold; */
      /* background-clip: text; */
      /* -webkit-background-clip: text; */
      color: white;
    }
    .lorem {
    }
    form {
      width: 50%;
      margin: auto;
    }
    textarea {
      color: white;
    }
    .lablecolor {
      color: white;
    }
    .submit {
      background: #377ceb 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 4px #1018280d;
      width: 100%;
      padding: 0.8rem;
      color: white;
      font-size: 25px;
      font: normal normal medium 53px/64px Rajdhani;
      letter-spacing: 2.65px;
      color: #ffffff;
      text-transform: uppercase;
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        transform: translateY(-5px);
      }
    }
    .checkbox {
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
      outline: 1px solid white;
    }
    .agreeDiv {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .agree {
      color: white;
      padding: 0 1.5rem;
    }
  }
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(11deg)
      brightness(112%) contrast(101%);
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    color: white;
  }
  .privacy {
    background-color: #2c6ca5;
    color: white;
    outline: 2px solid white;
    border-radius: 3px;
    padding:0.2rem 1rem;
    cursor: pointer;
    font-size: 12px;
    margin-left: 4px;
  }
`;
export default BookDemo;
