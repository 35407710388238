import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import contenet1 from "../../assets/content1.png";
import contenet2 from "../../assets/content2.png";
import contenet3 from "../../assets/content3.png";
import { StyledLink } from "../../style/NavBarStyle";
const Content = () => {
  const { singleService } = useSelector((state) => state?.services);

  return (
    <Bisgsection>
      {/* <p className="header">Content</p> */}
      <Grid container columns={12} pt={35} spacing={5}>
        {singleService?.catalogues?.map((e) => {
          return (
            <Grid item xs={12} md={6} lg={4}>
              <div className="singlediv">
                <div className="content">
                  <StyledLink to="/astrials">
                    <img src={e?.thumbnail_path}></img>
                    <p className="name">Astralis</p>
                    <p className="desc">
                      Get to know the sea and ocean inhabitants
                    </p>
                  </StyledLink>
                </div>
              </div>
            </Grid>
          );
        })}
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <StyledLink to="/astrials/1">
                <img src="https://thumb.tildacdn.com/tild3265-3430-4334-b363-306230636665/-/format/webp/VR_UnderWater-min.jpg"></img>
                <p className="name">Astralis</p>
                <p className="desc">
                  Get to know the sea and ocean inhabitants
                </p>
              </StyledLink>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <StyledLink to="/astrials/2">
                <img src="https://thumb.tildacdn.com/tild6563-6662-4336-a665-626135646435/-/format/webp/Waterfalls-min_1.jpg"></img>
                <p className="name">Astralis</p>
                <p className="desc">
                  Get to know the sea and ocean inhabitants
                </p>
              </StyledLink>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <StyledLink to="/astrials/3">
                <img src="https://thumb.tildacdn.com/tild3265-3430-4334-b363-306230636665/-/format/webp/VR_UnderWater-min.jpg"></img>
                <p className="name">Astralis</p>
                <p className="desc">
                  Get to know the sea and ocean inhabitants
                </p>
              </StyledLink>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <StyledLink to="/astrials/4">
                <img src="https://thumb.tildacdn.com/tild3265-3430-4334-b363-306230636665/-/format/webp/VR_UnderWater-min.jpg"></img>
                <p className="name">Astralis</p>
                <p className="desc">
                  Get to know the sea and ocean inhabitants
                </p>
              </StyledLink>
            </div>
          </div>
        </Grid>

        {/* <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <img src={contenet2}></img>
              <p className="name">Astralis</p>
              <p className="desc">Get to know the sea and ocean inhabitants</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <img src={contenet3}></img>
              <p className="name">Astralis</p>
              <p className="desc">Get to know the sea and ocean inhabitants</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <img src={contenet1}></img>
              <p className="name">Astralis</p>
              <p className="desc">Get to know the sea and ocean inhabitants</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <img src={contenet2}></img>
              <p className="name">Astralis</p>
              <p className="desc">Get to know the sea and ocean inhabitants</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <img src={contenet3}></img>
              <p className="name">Astralis</p>
              <p className="desc">Get to know the sea and ocean inhabitants</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <img src={contenet1}></img>
              <p className="name">Astralis</p>
              <p className="desc">Get to know the sea and ocean inhabitants</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <img src={contenet2}></img>
              <p className="name">Astralis</p>
              <p className="desc">Get to know the sea and ocean inhabitants</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="singlediv">
            <div className="content">
              <img src={contenet3}></img>
              <p className="name">Astralis</p>
              <p className="desc">Get to know the sea and ocean inhabitants</p>
            </div>
          </div>
        </Grid> */}
      </Grid>
    </Bisgsection>
  );
};
const Bisgsection = styled.div`
  padding: 0 4rem;
  padding-bottom: 15rem;
  .header {
    text-align: left !important;
    text-transform: uppercase;
    background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: bold;
    width: 340px;
  }
  .singlediv {
    img {
      width: 100%;
    }
    .content {
      width: 75%;
      text-align: center;
      .name {
        font-size: 28px;
        font-weight: 500;
      }
    }
    a {
      color: inherit;
    }
  }
`;

export default Content;
