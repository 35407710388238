import { Grid, TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import bg from "../assets/Contactus2.png";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label.Mui-root": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  input: {
    color: "white",
  },
});
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ContactUS = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Bigsection>
        <NavBar className="new"></NavBar>
        <form>
          {" "}
          <Grid container columns={12} spacing={2.5}>
            <Grid item xs={12}>
              <p className="header">Get in touch</p>
              <p>Our friendly team would love to hear from you.</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <CssTextField
                id="outlined-basic"
                label="First name"
                variant="outlined"
                size="medium"
                fullWidth
                InputLabelProps={{ className: "lablecolor" }}
              ></CssTextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <CssTextField
                id="outlined-basic"
                label="Last name"
                variant="outlined"
                size="medium"
                fullWidth
                InputLabelProps={{ className: "lablecolor" }}
              ></CssTextField>
            </Grid>
            <Grid item xs={12}>
              {" "}
              <CssTextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                size="medium"
                fullWidth
                InputLabelProps={{ className: "lablecolor" }}
              ></CssTextField>
            </Grid>
            <Grid item xs={12}>
              {" "}
              <CssTextField
                id="outlined-basic"
                label="Phone number"
                variant="outlined"
                size="medium"
                fullWidth
                InputLabelProps={{ className: "lablecolor" }}
              ></CssTextField>
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                id="outlined-basic"
                label="Message"
                variant="outlined"
                size="medium"
                fullWidth
                InputLabelProps={{ className: "lablecolor" }}
                multiline
                rows={5}
              ></CssTextField>
            </Grid>
            <Grid item xs={12} className="agreeDiv">
              <input type="checkbox" className="checkbox" />{" "}
              <p className="agree">You agree to our friendly privacy policy.</p>
            </Grid>
            <Grid item xs={12}>
              <button className="submit">submit</button>
            </Grid>
          </Grid>
        </form>
      </Bigsection>
      <Footer></Footer>
    </>
  );
};
const Bigsection = styled.div`
  text-align: center;
  background-color: black;
  padding-bottom: 2rem;
  /* min-height: 100vh; */
  background-image: url(${bg});
  background-attachment: fixed;
    background-position: top right;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: auto;
  .header {
    background: transparent
      linear-gradient(98deg, #2b2b2b 0%, #ffffff 0%, #4288ec 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
  }
  .lorem {
  }
  form {
    width: 50%;
    margin: auto;
  }
  textarea {
    color: white;
  }
  .lablecolor {
    color: white;
  }
  .submit {
    background: #377ceb 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #1018280d;
    width: 100%;
    padding: 0.8rem;
    color: white;
    font-size: 25px;
    font: normal normal medium 53px/64px Rajdhani;
    letter-spacing: 2.65px;
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s all;
    &:hover {
      transform: translateY(-5px);
    }
  }
  .checkbox {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    outline: 1px solid white;
  }
  .agreeDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .agree {
    color: white;
    padding: 0 1.5rem;
  }
  .new{
    background: transparent !important;
  }
`;
export default ContactUS;
